.filterHeadingGrid{
    padding: 1vh !important;
    padding-top: 3vh !important;
    padding-bottom: 1vh !important;
     }
     .filterHeading{
      font-family: 'Honeywell Sans Web'!important ;
      font-style: normal!important ;
      font-weight: 800!important ;
      font-size: 0.8rem!important ;
      display: flex!important ;
      align-items: center!important ;
      letter-spacing: 2.5px!important ;
      text-transform: uppercase!important ;
      }

      .filterby{
        font-family: 'Honeywell Sans Web'!important ;
    font-style: normal!important ;
    font-weight: 700!important ;
    font-size: 0.8 rem!important ;
    color: #F0F0F0!important ;
    padding: 1.5vh 0.8vh 0.4vh 0.8vh !important;
}

    .clearall{
        font-family: 'Honeywell Sans Web' !important ;
        font-style: normal !important ;
        font-weight: 700 !important ;
        font-size: 12px !important ;
        line-height: 20px !important ;    
        display: flex !important ;
        align-items: center !important ;
        text-align: center !important ;
        letter-spacing: 0.5px !important ;
          text-transform: none !important;
        color: #64C3FF !important ;
        padding: 2 !important;
      }

      .optionsCheck{
        font-family: 'Honeywell Sans Web'!important ;
        font-style: normal!important ;
        font-weight: 500!important ;
        font-size: 0.8rem!important ;
        color: #F0F0F0!important ;
        padding: 1% !important;
        padding-left: 5% !important;
    
    }
    .Check{
        font-family: 'Honeywell Sans Web'!important ;
        font-style: normal!important ;
        font-weight: 500!important ;
        font-size: 0.8rem!important ;
        /* color: #64C3FF    !important ; */
        padding: 1% !important;
        padding-left: '10%' !important;
        /* border-color: #F0F0F0 !important; */
    }

.subHeadingFilter{
    font-family: 'Honeywell Sans Web'!important ;
    font-style: normal!important ;
    font-weight: 700!important ;
    font-size: 0.8rem!important ;
    color: #F0F0F0!important ;
    padding: 1vh !important;
    padding-top: 3vh !important;
}

.filterButtonGrid {
    bottom: 5 !important;
    width: 95% !important;
    display: flex !important;
    position: absolute !important;
    padding: 1 !important;
    justify-content: unset !important;
}

.cancelButton {

    font-family: 'Honeywell Sans Web' !important;
    font-style: normal!important;
    font-weight: 700!important;
    font-size: 0.7rem !important;
    text-transform: none!important;
    display: flex!important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: 0.5px!important;
    color: #64C3FF!important;
    height:  4vh!important ;

    }

    .filterButtonShow{
        display: flex !important ;
        /* flex-direction: row !important ; */
        justify-content: center !important ;
        align-items: center !important ;
        /* padding: 6px 24px !important ; */
        width: 100% !important;
        gap: 8px !important ;
        height:  4vh!important ;
        background: #64C3FF !important ;    
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
        border-radius: 4px !important ;
        flex: none !important ;
        order: 1 !important ;
        flex-grow: 0 !important ;
        font-family: 'Honeywell Sans Web' !important ;
        font-style: normal !important ;
        font-weight: 800 !important ;
        font-size: 0.7rem !important ;
        display: flex !important ;
        align-items: center !important ;
        text-align: center !important ;
        letter-spacing: 0.5px !important ;
        color: #303030 !important ;
        text-transform: none !important ;
        mix-blend-mode: normal !important ;
        /* margin-left: 1vw !important; */
        }

.exportToast{
    background: #272727 !important ;
    box-shadow: 15px 30px 40px rgba(0, 0, 0, 0.4), 0px 11px 15px -2px rgba(0, 0, 0, 0.25) !important ;
    border-radius: 10px !important ;
    width: 100% !important;
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 500 !important ;
    font-size: 12px !important ;
    color: #F0F0F0 !important ;
        }
        
.exportToastMessage{
    border-radius: 8px !important ;
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 400 !important ;
    font-size: 10px !important ;
    color: #F0F0F0 !important ;
    padding: 4px 0px 4px 0px !important
        }  
        
        .refreshButton{
            display: flex !important ;
        justify-content: center !important ;
        align-items: center !important ;
        gap: 8px !important ;
        height:  4vh!important ;
       
        flex: none !important ;
        order: 1 !important ;
        flex-grow: 0 !important ;
        font-family: 'Honeywell Sans Web' !important ;
        font-style: normal !important ;
        font-weight: 800 !important ;
        font-size: 0.7rem !important ;
        display: flex !important ;
        align-items: center !important ;
        text-align: center !important ;
        letter-spacing: 0.5px !important ;
       
        text-transform: none !important ;
        mix-blend-mode: normal !important ;
        }

        .filterCloseIcon{
            background-Color:  inherit ;
            color:  white !important;
            border-block-color:  none ;
            position:  relative ;
            bottom:  0px ;
            padding:  0.0rem ;
        }

        .filterChips{
            color:#F0F0F0 !important;
            font-family: 'Honeywell Sans Web' !important ;
            font-style: normal !important ;
            font-weight: 500 !important ;
            font-size: 0.7rem !important ;
        border-radius: 0.2vh !important;
            border-color: #F0F0F0 !important;
            border: 1px solid #F0F0F0!important;
            margin:0vh !important;
            padding: 0vh 0.2vh 0vh 0.4vw !important;
            justify-content: center !important;
            justify-self: center !important;
            text-align: center !important;
            }