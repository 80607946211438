
.price {
    border-radius: 30px !important;
    padding: 0.15rem 1.5rem !important;
    background-color: #3a3a3a !important;
    color: white !important;
    font-size: 1.5rem !important;
  }
  .profileinfo {
    font-family:  Honeywell Sans Web  !important;
    font-style:  normal  !important;
    font-weight:  800  !important;
    font-size:  16px  !important;
    display:  flex  !important;
    letter-spacing:  2.5px  !important;
    text-transform:  uppercase  !important;
    color:  #F0F0F0  !important;
    margin-bottom:  8px  !important;
  }
  .mainheading {  
    font-family: Honeywell Sans Web !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    font-family: Honeywell Sans Web !important ;
  }
  
    .label{
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      display: flex !important;
      color: #F0F0F0 !important;
      align-items: center !important;
    }
    .labelProfile{
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      display: flex !important;
      color: #F0F0F0 !important;
      align-items: center !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    .dropdown {
      background: #353535 !important;
      border: 1px solid #101010 !important;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 4px !important;
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      display: flex !important;
      color: #F0F0F0 !important;
      /* height: '500px' !important; */
    }
  
    .image-upload > input
  {
    display: none !important;
  }
  
  .image-upload img
  {
    width: 80px !important;
    cursor: pointer !important;
  }
  

  
  .textField{
  background: #353535;
  border: 1px solid #101010;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 4px;
  height: 25px;
  }
  
  .createButton {
  background: #64C3FF !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px!important;
  /* padding: 2 !important;
  height: 12px !important; */
  /* position: relative !important; */
  font-family: 'Honeywell Sans Web'!important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 12px!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #303030!important;
  text-transform: none !important;
  }
  .cancelButton {
  
  font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 12px!important;
  text-transform: none!important;
  display: flex!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #64C3FF!important;
  }
  
  /* for user mgmt */
  
  .detailsUser{
  background: #313030 !important ;
  border: 1px solid #101010 !important ;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important ;
  border-radius: 10px !important ;
  }
  .totalUser{
    background: #313030 !important ;
    border: 1px solid #101010 !important ;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important ;
  }
  
  .labelHeading{
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 700 !important ;
  font-size: 12px !important ;
  color: #F0F0F0 !important ;
  flex: none !important ;
  order: 0 !important ;
  flex-grow: 0 !important ;
  min-width: '100px';
  width:'400px';
  }
  .labelValue{
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 12px !important ;
  display: flex !important ;
  align-items: center !important ;
  color: #E0E0E0 !important ;
  flex: none !important ;
  order: 1 !important ;
  flex-grow: 0 !important ;
  min-width: '100px';
  width:'400px';
  }
  .heading{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 900!important ;
  font-size: 20px!important ;
  }
  
  .totalCount {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 500!important ;
  font-size: 14px!important ;
  line-height: 20px!important ;
  display: flex!important ;
  color: #D0D0D0!important ;
  }
  
  .cardbox {
  background: #313030!important ;
  border: 1px solid #101010!important ;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15)!important ;
  border-radius: 10px!important ;
  }
  
  .secondaryHeading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 0.8rem !important ;
  /* identical to box height, or 150% */
  
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  color: #F0F0F0!important ;
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
  }
  
  .lowerCaseHeading {
    font-family: 'Honeywell Sans Web'!important ;
    font-style: normal!important ;
    font-weight: 800!important ;
    font-size: 12px!important ;
    /* identical to box height, or 150% */
    
    display: flex!important ;
    align-items: center!important ;
    text-align: center!important ;
    letter-spacing: 2.5px!important ;
    text-transform: none!important ;
    color: #F0F0F0!important ;
    flex: none!important ;
    order: 0!important ;
    flex-grow: 0!important ;
    }
  
  .filterButton{
  display: flex!important ;
  flex-direction: row!important ;
  justify-content: center!important ;
  align-items: center!important ;
  padding: 6px 24px 6px 16px!important ;
  gap: 8px!important ;
  color: #F0F0F0!important ;
  position: absolute!important ;
  
  background: #707070!important ;
  
  border: 1px solid #707070!important ;
  
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3)!important ;
  border-radius: 4px!important ;
  }
  
  .filterHeading{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 14px!important ;
  display: flex!important ;
  align-items: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  }
  
  .filterby{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 700!important ;
  font-size: 18px!important ;
  color: #F0F0F0!important ;
  /* padding-top: 2 !important;
  padding-bottom: 2 !important;
  padding: 2 !important; */
  
  
  }
  
  .subHeadingFilter{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 700!important ;
  font-size: 14px!important ;
  color: #F0F0F0!important ;
  }
  
  .optionsCheck{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 500!important ;
  font-size: 12px!important ;
  color: #F0F0F0!important ;
  
  }
  
  .submitFilter{
  font-family: Honeywell Sans Web !important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 14px!important ;
  line-height: 20px!important ;
  /* identical to box height, or 143% */
  
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 0.5px!important ;
  
  /* Typography (Dark)/Inverted Base #303030 */
  
  color: #303030!important ;
  
  mix-blend-mode: normal!important ;
  
  /* Inside auto layout */
  
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
  
  }
  
  .filterButtonShow{
  display: flex !important ;
  flex-direction: row !important ;
  justify-content: center !important ;
  align-items: center !important ;
  /* padding: 6px 24px !important ; */
  gap: 8px !important ;
  width: 130px !important ;
  height: 32px !important ;
  background: #64C3FF !important ;    
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
  border-radius: 4px !important ;
  flex: none !important ;
  order: 1 !important ;
  flex-grow: 0 !important ;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 800 !important ;
  font-size: 14px !important ;
  display: flex !important ;
  align-items: center !important ;
  text-align: center !important ;
  letter-spacing: 0.5px !important ;
  color: #303030 !important ;
  text-transform: none !important ;
  mix-blend-mode: normal !important ;
  /* margin-left: 20 !important; */
  }
  
  .createcustomer{
      background: #64C3FF !important;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 4px!important;
      /* padding: 2 !important;
      height: 12px !important; */
      position: relative !important; 
      font-family: 'Honeywell Sans Web'!important;
      font-style: normal!important;
      font-weight: 800!important;
      font-size: 12px!important;
      align-items: center!important;
      text-align: center!important;
      letter-spacing: 0.5px!important;
      color: #303030!important;
      text-transform: none !important;
      
      
  }
  
  .exportToast{
  background: #272727 !important ;
  box-shadow: 15px 30px 40px rgba(0, 0, 0, 0.4), 0px 11px 15px -2px rgba(0, 0, 0, 0.25) !important ;
  border-radius: 10px !important ;
  width: 100% !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 12px !important ;
  color: #F0F0F0 !important ;
  }
  
  .exportToastMessage{
  border-radius: 8px !important ;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 400 !important ;
  font-size: 10px !important ;
  color: #F0F0F0 !important ;
  padding: 4px 0px 4px 0px !important
  }

  
.dialogCancelButton{
    font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 12px!important;
  text-transform: none!important;
    /* box-sizing: border-box !important ; */
    background: #272727 !important ;
    border: 1.5px solid #64C3FF !important ;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
    border-radius: 4px !important ;
    font-size: 12px !important ;
    padding: 4px 15px  !important ;
    color:  #64C3FF !important ;
  
    }
  
      .dialogCreateButton{
        font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 12px!important;
  text-transform: none!important;
  color: #303030!important;
        border: 1.5px solid #64C3FF !important ;
        font-size: 12px !important ;
  display: flex !important ;
  flex-direction: row !important ;
  background: #64C3FF !important ;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
  border-radius: 4px !important ;
  padding: 4px 15px  !important ;
  margin-right: 20px;
      }
          
          .dialogHeading{
            font-family: 'Honeywell Sans Web' !important ;
            font-style: normal !important ;
            font-weight: 700 !important ;
            font-size: 18px !important ;
            display: flex !important ;
            align-items: center !important ;
            color: #F0F0F0 !important ;
            background-color: #272727;
            width: 400px;
            height: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            }
           
            .dialogInfo{
              font-family: 'Honeywell Sans Web' !important ;
              font-style: normal !important ;
              font-weight: 500 !important ;
              font-size: 16px !important ;
              color: #F0F0F0 !important ;
        
            }

          