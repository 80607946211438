.mainheading {
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 1.1rem !important;
    font-family: Honeywell Sans Web !important;
}
.paper{
    flex-grow: 1 !important ;
    height: 90vh !important ;
    min-width: 800px !important ;
    width: 100% !important ;
    background-color: #202020 !important ;
    margin-top : 1vh !important ;
  }
  .grid-Heading{
    width: 100%;
    }

.detailsUser {
    background: #313030 !important;
    border: 1px solid #101010 !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important;
    /* border-radius: 10px !important ; */
}

.secondaryHeading {
    font-family: 'Honeywell Sans Web' !important;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 12px !important;
    padding: 0 !important;
    padding-left: 7px !important;
    /* identical to box height, or 150% */

    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #F0F0F0 !important;
    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;
}



.editUser {
    background: #64C3FF !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0.4vh!important;
    font-family: 'Honeywell Sans Web'!important;
    font-style: normal!important;
    font-weight: 800!important;
    height: 3vh !important;
    font-size: 0.7rem !important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: 0.5px!important;
    color: #303030!important;
    text-transform: none !important;;
}

.deleteButton {
  
    font-family: 'Honeywell Sans Web' !important;
    font-style: normal!important;
    font-weight: 800!important;
    font-size: 0.7rem !important;
    text-transform: none!important;
    display: flex!important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: 0.5px!important;
    color: #64C3FF!important;
    height: 3vh !important;
    padding: 1vh !important;
}

.cancelcreatebtnsbox {
    width: 100%;
    height: 80px;
    min-height: 56px;
    background: #272727;
    vertical-align: middle;
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 30px 32px 30px 54px;
}



.labelHeading{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 700 !important ;
    font-size: 0.8rem !important ;
    color: #F0F0F0 !important ;
    flex: none !important ;
    order: 0 !important ;
    flex-grow: 0 !important ;
    min-width: 10vw !important;
    
    }
    .labelValue{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 500 !important ;
    font-size: 0.8rem !important ;
    display: flex !important ;
    align-items: center !important ;
    color: #E0E0E0 !important ;
    flex: none !important ;
    /* order: 1 !important ; */
    flex-grow: 1 !important ;
    /* width:11vw !important; */
    min-width: 10vw !important;
    }