.hearderStyle
{
font-family: 'Honeywell Sans Web' !important ;
font-size: '14px' !important ;
font-weight: 700 !important ;
font-style: normal !important ;
display: flex!important ;
align-items: left!important ;
color: #F0F0F0!important ;
letter-spacing: 1.5px !important;

}

.contentStyle
{
    font-family: Honeywell Sans Web !important ;
    font-size: 12px !important ;
    font-weight: 500 !important ;
}

.okButton{
  margin: 20px !important;
background: linear-gradient(137.09deg, #64BBFF 11.65%, #5DEAFC 73.48%) !important ;
/* Dark/ Shadow_1dp */

box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
border-radius: 4px !important ;
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 700 !important ;
font-size: 12px !important ;
justify-content: center !important ;
align-items: center !important ;
/* identical to box height, or 143% */

display: flex !important ;
align-items: center !important ;
letter-spacing: 0.5px !important ;

/* Typography (Dark)/Inverted Base #303030 */

color: #303030 !important ;
mix-blend-mode: normal !important ;
text-transform: none!important;
}