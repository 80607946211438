.text-input-label {
  font-size: 0.8rem;
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  // margin-bottom: 8px;
}

.text-input-field {
  background: #353535 !important;
  border: 1px solid #303030 !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 4px !important;
  height: 3.5vh !important;
  font-size: 0.7 rem !important;
  /* color: #F0F0F0; */
  color: 'red';
  min-height: '15px' !important;
  box-sizing: border-box !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
}

.edit-text-input-field {
  background: #272727 !important;
  border: 1px solid #505050 !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 4px !important;
  height: 3.5vh !important;
  font-size: 0.7 rem !important;
  /* color: #F0F0F0; */
  color: 'red';
  min-height: '15px' !important;
  box-sizing: border-box !important;
// box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
border-radius: 4px !important;
}

.css-1t5my89-MuiInputBase-root-MuiOutlinedInput-root {
    height: 3.5vh !important;
    // height: ;
}

.css-ybz86d-MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}