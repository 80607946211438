.labelHeading{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 700 !important ;
    font-size: 0.8rem !important ;
    color: #F0F0F0 !important ;
    flex: none !important ;
    order: 0 !important ;
    flex-grow: 0 !important ;
    min-width: 10vw !important;
    
    }
    .labelValue{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 500 !important ;
    font-size: 0.8rem !important ;
    display: flex !important ;
    align-items: center !important ;
    color: #E0E0E0 !important ;
    flex: none !important ;
    order: 1 !important ;
    flex-grow: 0 !important ;
    // width:11vw !important;
    min-width: 10vw !important;
    }