.accept-terms-grid {
  display: flex;
  align-items: center;
  width: 99% !important;
  height: auto;
  min-height: 56px;
  background: #272727;
  margin-top: 1vh;
  min-width: 850px;
}

.MuiTooltip-arrow::before {
  background-color: #f0f0f0 !important;
}
.terms-label{
  font-size: 0.8rem;
  font-family: Honeywell Sans Web;
  font-weight: 500;
}
