
.price {
  border-radius: 30px !important;
  padding: 0.15rem 1.5rem !important;
  background-color: #3a3a3a !important;
  color: white !important;
  font-size: 1.5rem !important;
}
.profileinfo {
  font-family:  Honeywell Sans Web  !important;
  font-style:  normal  !important;
  font-weight:  800  !important;
  font-size:  16px  !important;
  display:  flex  !important;
  letter-spacing:  2.5px  !important;
  text-transform:  uppercase  !important;
  color:  #F0F0F0  !important;
  margin-bottom:  8px  !important;
}
.mainheading {  
  font-family: Honeywell Sans Web !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 1.1rem !important;
  font-family: Honeywell Sans Web !important ;
}

.createUser{
  background: #64C3FF !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0.4vh!important;
  height: 3.5vh !important;   /* padding: 2 !important;
  height: 12px !important; */
  position: relative !important; 
  font-family: 'Honeywell Sans Web'!important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 0.8rem!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #303030!important;
  text-transform: none !important;
  /* padding: 1vh !important; */
  margin: 1vh !important;
  
}

.cancelButton {

  font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 700!important;
  font-size: 0.7rem !important;
  text-transform: none!important;
  display: flex!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #64C3FF!important;
  height:  4vh!important ;

  }
  .label{
    font-family: Honeywell Sans Web !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    display: flex !important;
    color: #F0F0F0 !important;
    align-items: center !important;
  }
  .labelProfile{
    font-family: Honeywell Sans Web !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    display: flex !important;
    color: #F0F0F0 !important;
    align-items: center !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .dropdown {
    background: #353535 !important;
    border: 1px solid #101010 !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
    border-radius: 4px !important;
    font-family: Honeywell Sans Web !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    display: flex !important;
    color: #F0F0F0 !important;
    /* height: '500px' !important; */
  }

  .image-upload > input
{
  display: none !important;
}

.image-upload img
{
  width: 80px !important;
  cursor: pointer !important;
}

.secondaryHeading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 0.8rem!important ;
  padding: 0 !important;
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  color: #F0F0F0!important ;
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
  }

.textField{
background: #353535;
border: 1px solid #101010;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
box-shadow: 4px;
height: 25px;
}

.totalCount {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 500!important ;
  font-size: 0.7rem!important ;
  display: flex!important ;
  color: #D0D0D0!important ;
}

.createButton {
background: #64C3FF !important;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
border-radius: 4px!important;
/* padding: 2 !important;
height: 12px !important; */
/* position: relative !important; */
font-family: 'Honeywell Sans Web'!important;
font-style: normal!important;
font-weight: 800!important;
font-size: 12px!important;
align-items: center!important;
text-align: center!important;
letter-spacing: 0.5px!important;
color: #303030!important;
text-transform: none !important;
}




.deleteButton {
  background: #64C3FF !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0.4vh!important;
  font-family: 'Honeywell Sans Web'!important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 0.7rem !important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #303030!important;
  text-transform: none !important;
  padding: '1vh' !important;
  display: flex!important;
  align-items: center!important;
  height: 4vh !important;  
  margin: 1vw !important;
  position: relative  !important;
  padding: 1vh  !important;
  margin-right: 3vw !important;

  }


/* for user mgmt */

.detailsUser{
/* background: #313030 !important ; */
border: 1px solid #101010 !important ;
/* box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important ; */
/* border-radius:  !important ; */
width: 100% !important ;
                background: #313030 !important ;
                box-shadow:
                  1px 1px 1px rgba(0, 0, 0, 0.15), 1px 1px 1px rgba(0, 0, 0, 0.15)!important ;
                /* padding: 0.5vh !important ; */
                /* min-width: 900px !important ; */
}

.filterHeadingGrid{
  padding: 1vh !important;
  padding-top: 3vh !important;
  padding-bottom: 1vh !important;
   }

.totalUser{
  background: #313030 !important ;
  border: 1px solid #101010 !important ;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15) !important ;
}

.labelHeading{
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 700 !important ;
font-size: 0.7rem !important ;
color: #F0F0F0 !important ;
flex: none !important ;
order: 0 !important ;
flex-grow: 0 !important ;
min-width: 10vw !important;

}
.labelValue{
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 500 !important ;
font-size: 0.7rem !important ;
display: flex !important ;
align-items: center !important ;
color: #E0E0E0 !important ;
flex: none !important ;
order: 1 !important ;
flex-grow: 0 !important ;
width:11vw !important;
min-width: 10vw !important;
}
.heading{
font-family: 'Honeywell Sans Web'!important ;
font-style: normal!important ;
font-weight: 900!important ;
font-size: 20px!important ;
}


.nofilter{
  color:#F0F0F0 !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 0.65rem !important ;
border-radius: 0.2vh !important;
  border-color: #272727 !important;
  border: 1px solid #272727!important;
  margin:0.2vh !important;
  padding: 0.5vh !important;
  }

.editButton {
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 700 !important ;
  font-size: 10px !important ;
  justify-content: start !important ;
  display: flex !important ;
  align-items: center !important ;
  text-align: center !important ;
  letter-spacing: 0.5px !important ;
  padding: 0 !important ;
  color: 'red' !important;
  background-color: 'pink' !important;
}

.cardbox {
background: #313030!important ;
border: 1px solid #101010!important ;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15)!important ;
border-radius: 10px!important ;
}



.lowerCaseHeading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800 !important ;
  font-size: 0.7rem !important ;  
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: none!important ;
  color: #F0F0F0!important ;
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
padding: 1vh !important;  
  }

.filterButton{
display: flex!important ;
flex-direction: row!important ;
justify-content: center!important ;
align-items: center!important ;
padding: 6px 24px 6px 16px!important ;
gap: 8px!important ;
color: #F0F0F0!important ;
position: absolute!important ;

background: #707070!important ;

border: 1px solid #707070!important ;

box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3)!important ;
border-radius: 4px!important ;
}

.filterHeading{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 0.8rem!important ;
  display: flex!important ;
  align-items: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  }

  .clearall{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 700 !important ;
    font-size: 12px !important ;
    display: flex !important ;
    align-items: center !important ;
    text-align: center !important ;
    letter-spacing: 0.5px !important ;
      text-transform: none !important;
    color: #64C3FF !important ;
    padding: 2 !important;
  }

.filterby{
  font-family: 'Honeywell Sans Web'!important ;
font-style: normal!important ;
font-weight: 700!important ;
font-size: 0.8 rem!important ;
color: #F0F0F0!important ;
padding: 1.5vh 0.8vh 0.4vh 0.8vh !important;
}

.headinginfo{
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 800 !important ;
font-size: 0.8rem !important ;
color: #E0E0E0 !important ;
padding: 0.3vh !important;


}

.subHeadingFilter{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 700!important ;
  font-size: 0.8rem!important ;
  color: #F0F0F0!important ;
  padding: 1vh !important;
  padding-top: 3vh !important;
}

.optionsCheck{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 500!important ;
  font-size: 0.8rem!important ;
  color: #F0F0F0!important ;
  padding: 1% !important;
  padding-left: 5% !important;

}
.Check{
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 500!important ;
  font-size: 0.8rem!important ;
  /* color: #64C3FF    !important ; */
  padding: 1% !important;
  padding-left: '10%' !important;
  /* border-color: #F0F0F0 !important; */
}

.submitFilter{
font-family: Honeywell Sans Web !important ;
font-style: normal!important ;
font-weight: 800!important ;
font-size: 14px!important ;
/* identical to box height, or 143% */

display: flex!important ;
align-items: center!important ;
text-align: center!important ;
letter-spacing: 0.5px!important ;

/* Typography (Dark)/Inverted Base #303030 */

color: #303030!important ;

mix-blend-mode: normal!important ;

/* Inside auto layout */

flex: none!important ;
order: 0!important ;
flex-grow: 0!important ;

}
.filterChips{
  color:#F0F0F0 !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 0.7rem !important ;
border-radius: 0.2vh !important;
  border-color: #F0F0F0 !important;
  border: 1px solid #F0F0F0!important;
  margin:0vh 0.3vh 0vh 0vh !important;
  padding: 0.1vh 0.2vw 0.1vh 0.4vw !important;
  justify-content: center !important;
  justify-self: center !important;
  text-align: center !important;
  }

.paper{
  flex-grow: 1 !important ;
  height: 94vh !important ;
  min-width: 800px !important ;
  width: 90vh !important ;
  background-color: #202020 !important ;
  margin-top : 1vh !important ;
  flex-grow: 1 !important;
}

.filterButtonShow{
  display: flex !important ;
  /* flex-direction: row !important ; */
  justify-content: center !important ;
  align-items: center !important ;
  /* padding: 6px 24px !important ; */
  width: 100% !important;
  gap: 8px !important ;
  height:  4vh!important ;
  background: #64C3FF !important ;    
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
  border-radius: 4px !important ;
  flex: none !important ;
  order: 1 !important ;
  flex-grow: 0 !important ;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 800 !important ;
  font-size: 0.7rem !important ;
  display: flex !important ;
  align-items: center !important ;
  text-align: center !important ;
  letter-spacing: 0.5px !important ;
  color: #303030 !important ;
  text-transform: none !important ;
  mix-blend-mode: normal !important ;
  /* margin-left: 1vw !important; */
  }


.filterButtonGrid{
  bottom: 5 !important ;
  width: 95% !important ;
  display: flex !important ;
  position: absolute !important ;
  padding: 1 !important ;
  justify-content: unset !important ;
}

.createcustomer{
    background: #64C3FF !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
    border-radius: 4px!important;
    /* padding: 2 !important;
    height: 12px !important; */
    position: relative !important; 
    font-family: 'Honeywell Sans Web'!important;
    font-style: normal!important;
    font-weight: 800!important;
    font-size: 12px!important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: 0.5px!important;
    color: #303030!important;
    text-transform: none !important;
    
    
}

.exportToast{
background: #272727 !important ;
box-shadow: 15px 30px 40px rgba(0, 0, 0, 0.4), 0px 11px 15px -2px rgba(0, 0, 0, 0.25) !important ;
border-radius: 10px !important ;
width: 100% !important;
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 500 !important ;
font-size: 12px !important ;
color: #F0F0F0 !important ;
}

.exportToastMessage{
border-radius: 8px !important ;
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 400 !important ;
font-size: 10px !important ;
color: #F0F0F0 !important ;
padding: 4px 0px 4px 0px !important
}

.deleteUser{
  background: #64C3FF !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px!important;
  /* padding: 2 !important;
  height: 12px !important; */
  position: relative !important; 
  font-family: 'Honeywell Sans Web'!important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 12px!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #303030!important;
  text-transform: none !important;
}

.cancelButtonDelete {
  font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 0.7rem !important;
  text-transform: none!important;
  display: flex!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #64C3FF!important;
  height: 4vh !important;  
  margin: 1vw !important;
  position: relative  !important;
  padding: 1vh  !important;
  }

  .refreshButton{
    display: flex !important ;
justify-content: center !important ;
align-items: center !important ;
gap: 8px !important ;
height:  4vh!important ;

flex: none !important ;
order: 1 !important ;
flex-grow: 0 !important ;
font-family: 'Honeywell Sans Web' !important ;
font-style: normal !important ;
font-weight: 800 !important ;
font-size: 0.7rem !important ;
display: flex !important ;
align-items: center !important ;
text-align: center !important ;
letter-spacing: 0.5px !important ;

text-transform: none !important ;
mix-blend-mode: normal !important ;
}

.filterCloseIcon{
  background-Color:  inherit ;
  color:  white !important;
  border-block-color:  none ;
  position:  relative ;
  bottom:  0px ;
  padding:  0.1rem !important;
}