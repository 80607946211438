.secondaryHeading {
    font-family: 'Honeywell Sans Web'!important ;
    font-style: normal!important ;
    font-weight: 800!important ;
    font-size: 0.8rem !important ;
    padding: 1% !important;
    display: flex!important ;
    align-items: center!important ;
    text-align: center!important ;
    letter-spacing: 2.5px!important ;
    text-transform: uppercase!important ;
    color: #F0F0F0!important ;
    flex: none!important ;
    order: 0!important ;
    flex-grow: 0!important ;
    }