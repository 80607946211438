
  .price {
    border-radius: 30px !important;
    padding: 0.15rem 1.5rem !important;
    background-color: #3a3a3a !important;
    color: white !important;
    font-size: 1.5rem !important;
  }
  .profileinfo {
    font-family:  Honeywell Sans Web  !important;
    font-style:  normal  !important;
    font-weight:  800  !important;
    font-size:  16px  !important;
    display:  flex  !important;
    letter-spacing:  2.5px  !important;
    text-transform:  uppercase  !important;
    color:  #F0F0F0  !important;
    margin-bottom:  8px  !important;
  }
.requiredText{
  margin-left: 14px !important;
  color: #D32F2F !important;
  font-weight: 400 !important;
  font-size: 0.6rem !important;
  font-family: "Roboto","Helvetica","Arial",sans-serif !important;
  margin-top: 0 !important;

}
  .AvatarBox{
    border-radius: 50% !important ;
    display: flex !important ;
    height: 13vh !important ;
    width: 13vh !important ;
    background-color: #101010 !important ;
    background-repeat: no-repeat  !important ;
    background-size: cover !important ;
    border-block-end-color: #101010 !important ;
    border-bottom-color:#101010  !important ;
    border-right-color: #101010 !important ;
    border-left: #101010 !important ;
    border-block-start:#101010  !important ;
  }
  .mainheading {  
    font-family: Honeywell Sans Web !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 1.1rem !important;
    font-family: Honeywell Sans Web !important ;
  }

    .label{
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 0.8rem !important;
      display: flex !important;
      color: #F0F0F0 !important;
      align-items: center !important;
      padding: .5vh !important;
    }

    .labelNew{
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 0.8rem !important;
      display: flex !important;
      color: #F0F0F0 !important;
      align-items: center !important;
      padding: 0.2vh !important;
      /* margin: 0vh !important; */
    }
    
    /* .labelProfile{
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 12px !important;
      display: flex !important;
      color: #F0F0F0 !important;
      align-items: center !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    } */
    .dropdown {
      background: #353535 !important;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 4px !important;
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 0.7rem !important;
      color: #F0F0F0 !important;
      /* height: '500px' !important; */
    }

    .dropdownNew {
      background: #353535 !important;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
      border-radius: 4px !important;
      font-family: Honeywell Sans Web !important;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 0.7rem !important;
      color: #F0F0F0 !important;
      /* height: '500px' !important; */
      /* margin: 0px !important;   */
      padding: 0% !important;

    }

    .image-upload > input
{
    display: none !important;
}

.image-upload img
{
    width: 80px !important;
    cursor: pointer !important;
}

.setBoxTextFields{
  /* sx={{ margin: "1vh", padding: "0.5vh", paddingTop: "2vh" }} */
  margin: 1vh !important ;
 padding: 0.5vh !important ;
  padding-top: 0.7vh !important ;
min-height: 55px !important;
  
}


.setBoxTextFieldsNew{
  /* sx={{ margin: "1vh", padding: "0.5vh", paddingTop: "2vh" }} */
  margin: 0vh 1vh 0vh 1vh !important ;
 padding: 0.5vh !important ;
  /* padding-top: 0.7vh !important ; */
min-height: 55px !important;
  
}

.setBoxTextFieldsOther{
  /* sx={{ margin: "1vh", padding: "0.5vh", paddingTop: "2vh" }} */
  margin: 1vh !important ;
 padding: 0.5vh !important ;
  padding-top: 0.5vh !important ;
}

.secondaryHeading {
font-family: 'Honeywell Sans Web'!important ;
font-style: normal!important ;
font-weight: 800!important ;
font-size: 0.8rem !important ;
/* line-height: 24px!important ; */
padding: 1vh !important;
/* identical to box height, or 150% */
display: flex!important ;
align-items: center!important ;
text-align: center!important ;
letter-spacing: 2.5px!important ;
text-transform: uppercase!important ;
color: #F0F0F0!important ;
flex: none!important ;
order: 0!important ;
flex-grow: 0!important ;
}

.textField{
  background: #353535 !important;
  border: 1px solid #303030 !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 4px !important;
  height: 3.5vh !important;
  font-size: 0.7 rem !important;
  /* color: #F0F0F0; */
  color: 'red';
  box-sizing: border-box !important;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
border-radius: 4px !important;
padding-bottom: 0 !important;
}

.uneditableField{
  background: #272727 !important;
  border: 1px solid #505050 !important;
  height: 3.5vh !important;
  font-size: 0.7 rem !important;
  box-sizing: border-box !important;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
border-radius: 4px !important;
min-height: 15px !important;
}

.createButton {
background: #64C3FF !important;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
border-radius: 0.4vh!important;
font-family: 'Honeywell Sans Web'!important;
font-style: normal!important;
font-weight: 800!important;
height: 3vh !important;
font-size: 0.7rem !important;
align-items: center!important;
text-align: center!important;
letter-spacing: 0.5px!important;
color: #303030!important;
text-transform: none !important;
}
.cancelButton {

font-family: 'Honeywell Sans Web' !important;
font-style: normal!important;
font-weight: 800!important;
font-size: 0.7rem !important;
text-transform: none!important;
display: flex!important;
align-items: center!important;
text-align: center!important;
letter-spacing: 0.5px!important;
color: #64C3FF!important;
height: 3vh !important;
}

  
  .dialogHeading{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 700 !important ;
    font-size: 18px !important ;
    display: flex !important ;
    align-items: center !important ;
    color: #F0F0F0 !important ;
    background-color: #272727;
    width: 400px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    }
   
    .dialogInfo{
      font-family: 'Honeywell Sans Web' !important ;
      font-style: normal !important ;
      font-weight: 500 !important ;
      font-size: 16px !important ;
      color: #F0F0F0 !important ;

    }

.dialogCancelButton{
  font-family: 'Honeywell Sans Web' !important;
font-style: normal!important;
font-weight: 700!important;
font-size: 12px!important;
text-transform: none!important;
  /* box-sizing: border-box !important ; */
  background: #272727 !important ;
  border: 1.5px solid #64C3FF !important ;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
  border-radius: 4px !important ;
  font-size: 12px !important ;
  padding: 4px 15px  !important ;
  color:  #64C3FF !important ;

  }

    .dialogCreateButton{
      font-family: 'Honeywell Sans Web' !important;
font-style: normal!important;
font-weight: 800!important;
font-size: 0.7rem !important;
text-transform: none!important;
color: #303030!important;
border: 1.5px solid #64C3FF !important ;
display: flex !important ;
flex-direction: row !important ;
background: #64C3FF !important ;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important ;
border-radius: 4px !important ;
padding: 4px 15px  !important ;
margin-right: 20px;
/* margin-left: auto !important; */
    }
.editImage {
  height: 13vh !important; 
  width: 13vh !important;
  position: flex !important;
}
.placeholder{
  color: #706D6D !important;
font-weight : 800 !important;
  font-style: normal !important;
  font-size: 0.67rem !important;
  font-family: Honeywell Sans Web !important;
}

.paper{
  flex-grow: 1 !important ;
  height: 90vh !important ;
  min-width: 800px !important ;
  width: 100% !important ;
  background-color: #202020 !important ;
  margin-top : 1vh !important ;
}
.profileGrid{
  min-height: 470px !important ;
  width: 33% !important ;
  background-color: #272727 !important ;
  border: 1px solid #101010 !important ;
  box-shadow:
    1px 1px 1px rgba(0, 0, 0, 0.15), 1px 1px 1px rgba(0, 0, 0, 0.15) !important ;
  border-radius: .7vw !important ;
  height: 77vh !important ;
  min-height: 470px !important;
  margin-right: 1% !important ;
  padding: 1vh,
  
}



