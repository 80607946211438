.dialogHeading {
  font-family: "Honeywell Sans Web" !important ;
  font-style: normal !important ;
  font-weight: 700 !important ;
  font-size: 18px !important ;
  display: flex !important ;
  align-items: center !important ;
  color: #f0f0f0 !important ;
  background-color: #272727;
  width: 400px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialogInfo {
  width: 320px;
  font-family: "Honeywell Sans Web" !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 16px !important ;
  color: #f0f0f0 !important ;
  word-wrap: break-word;
}


