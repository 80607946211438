.create-user-dashboard {
  flex-grow: 1 !important ;
  height: 90vh !important ;
  min-width: 800px !important ;
  width: 100% !important ;
  background-color: #202020 !important ;
  margin-top : 1vh !important ;
  width: 100vw;
  background-color: #202020;


  .paper{
    flex-grow: 1 !important ;
    height: 90vh !important ;
    min-width: 800px !important ;
    width: 100% !important ;
    background-color: #202020 !important ;
    margin-top : 1vh !important ;
  }
  .title-block {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    .css-i4bv87-MuiSvgIcon-root {
      height:16px;
      width: 27px;
      margin: 6px 0;
    }
   

    h4 {
      height: 32px;
      font-family: "Honeywell Sans Web";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 32px;
      /* identical to box height, or 133% */

      color: #f0f0f0;
      margin: 0;
      margin-right: 16px;
    }
  }

  h5 {
    font-family: "Honeywell Sans Web";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 32px 0;
  }
}

.profile-info-container {
  width: 100%;
  height: 20vh;
  background-color: "#202020";
}

.title {
  font-weight: 900 !important;
  font-size: 20px !important;
  margin-bottom: 32px;
}

.menu-item {
  font-size: 10px;
}

.MuiAutocomplete-tag {
  background-color: #272727;
  border-radius: 5px;
  height: 100%;
  border: 1px solid #d0d0d0;
}

.form-grid-container {
  padding: 24px;
  margin-bottom: 16px;
}

.cancelButton {

  font-family: 'Honeywell Sans Web' !important;
  font-style: normal!important;
  font-weight: 800!important;
  font-size: 0.7rem !important;
  text-transform: none!important;
  display: flex!important;
  align-items: center!important;
  text-align: center!important;
  letter-spacing: 0.5px!important;
  color: #64C3FF!important;
  height: 3vh !important;
  padding: 1vh !important;
  }

  .createButton {
    background: #64C3FF !important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
    border-radius: 0.4vh!important;
    font-family: 'Honeywell Sans Web'!important;
    font-style: normal!important;
    font-weight: 800!important;
    height: 3vh !important;
    font-size: 0.7rem !important;
    align-items: center!important;
    text-align: center!important;
    letter-spacing: 0.5px!important;
    color: #303030!important;
    text-transform: none !important;
    }

.cancel-create-btns-box {
  width: 97%;
  height: 4vh;
  min-height: 56px;
  background: #272727;
  vertical-align: middle;
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 30px 32px 30px 54px;
}

.image-upload > input {
  display: none !important;
}

.image-upload img {
  width: 80px !important;
  cursor: pointer !important;
}
.grid-Heading{
width: 100%;
background-color: 'red';
}
.mainheading {  
  font-family: Honeywell Sans Web !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-size: 1.1rem !important;
  font-family: Honeywell Sans Web !important ;
}

.secondaryHeading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 0.8rem !important ;
  padding: 1% !important;
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  color: #F0F0F0!important ;
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
  }

  .AvatarBox{
    border-radius: 50% !important ;
    display: flex !important ;
    height: 22vh !important ;
    width: 22vh !important ;
    background-color: #101010 !important ;
    background-repeat: no-repeat  !important ;
    background-size: cover !important ;
    border-block-end-color: #101010 !important ;
    border-bottom-color:#101010  !important ;
    border-right-color: #101010 !important ;
    border-left: #101010 !important ;
    border-block-start:#101010  !important ;
min-height: 120px !important;
min-width: 120px !important;
    // margin: 2vh !important;
  }

  .labelHeading{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 700 !important ;
    font-size: 0.8rem !important ;
    color: #F0F0F0 !important ;
    flex: none !important ;
    order: 0 !important ;
    flex-grow: 0 !important ;
    min-width: 10vw !important;
    
    }
    .labelValue{
    font-family: 'Honeywell Sans Web' !important ;
    font-style: normal !important ;
    font-weight: 500 !important ;
    font-size: 0.8rem !important ;
    display: flex !important ;
    align-items: center !important ;
    color: #E0E0E0 !important ;
    flex: none !important ;
    order: 1 !important ;
    flex-grow: 0 !important ;
    width:11vw !important;
    min-width: 10vw !important;
    }