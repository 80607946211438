.primary-btn {
  height: 32px;
  background: #64c3ff !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;
  font-family: "Honeywell Sans Web" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 12px !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  color: #303030 !important;
  text-transform: none !important;
}

.text-btn {
  font-family: "Honeywell Sans Web" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  text-transform: none !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  color: #64c3ff !important;
  margin-left: 56px;
}
