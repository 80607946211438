@mixin basic-info-title {
  font-family: "Honeywell Sans Web";
  margin-bottom: 24px;
  font-size: 16px;
  height: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 20px;
  color: #e0e0e0;
}

.hw-user-info-container {
  width: 100vw;
  padding: 16px;

  .hw-user-info-paper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    background-color: #313030;
    padding: 24px;

    .basic-info-title {
      @include basic-info-title();
    }

    .other-info-title {
      @include basic-info-title();
    }

    .label {
      height: 24px;
      font-family: "Honeywell Sans Web";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #f0f0f0;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .field-value {
      font-family: "Honeywell Sans Web";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #e0e0e0;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}

.paper{
  flex-grow: 1 !important ;
  height: 94vh !important ;
  min-width: 800px !important ;
  width: 90vh !important ;
  background-color: #202020 !important ;
  margin-top : 1vh !important ;
  /* flex-grow: 1 !important; */
}

.heading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 900!important ;
  font-size: 1.1rem!important ;
}

.totalCount {
  font-family: 'Honeywell Sans Web'!important ;
    font-style: normal!important ;
    font-weight: 500!important ;
    font-size: 0.68rem!important ;
    line-height: 20px!important ;
    display: flex!important ;
    color: #D0D0D0!important ; 
    margin-top: 0.5vh !important;

}

.createuser {
  background: #64C3FF !important;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
        position: relative !important; 
        font-family: 'Honeywell Sans Web'!important;
        font-style: normal!important;
        font-weight: 800!important;
        font-size: 0.8rem !important;
        align-items: center!important;
        text-align: center!important;
        letter-spacing: 0.5px!important;
        color: #303030!important;
        text-transform: none !important;
        background: #64C3FF !important;
border-radius: 0.4vh!important;
height: 3.5vh !important; 
}

.secondaryHeading {
  font-family: 'Honeywell Sans Web'!important ;
  font-style: normal!important ;
  font-weight: 800!important ;
  font-size: 0.8rem!important ;
  display: flex!important ;
  align-items: center!important ;
  text-align: center!important ;
  letter-spacing: 2.5px!important ;
  text-transform: uppercase!important ;
  color: #F0F0F0!important ;
  flex: none!important ;
  order: 0!important ;
  flex-grow: 0!important ;
}
.filterChips{
  color:#F0F0F0 !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 0.7rem !important ;
border-radius: 0.2vh !important;
  border-color: #F0F0F0 !important;
  border: 1px solid #F0F0F0!important;
  margin:0.3vh !important;
  padding: 0.5vh !important;
  justify-content: center !important;
  justify-self: center !important;
  text-align: center !important;
  }

.nofilter{
  color:#F0F0F0 !important;
  font-family: 'Honeywell Sans Web' !important ;
  font-style: normal !important ;
  font-weight: 500 !important ;
  font-size: 0.7rem !important ;
border-radius: 0.2vh !important;
  border-color: #272727 !important;
  border: 1px solid #272727!important;
  margin:0.3vh !important;
  padding: 0.5vh !important;
  justify-content: center !important;
  justify-self: center !important;
  text-align: center !important;
  }
